<template>
  <a-col :sm="24" :xs="24">
    <a-row :gap="2">
      <a-col :xs="24">
        <a-select
          v-model:value="value"
          class="native-select"
          :style="{ height: '45px' }"
          :placeholder="$t('generic.titles.select_search')"
          :options="searchFilters"
          :ref="selectRef"
          :loading="isLoading"
          @select="handleSelect"
        ></a-select>
      </a-col>
      <a-col :xs="24" style="padding-left: 8px;">
        <p class="the-checkbox">
          <a-checkbox v-model:checked="auto">
            {{ $t("search_funds.auto_filter") }}
          </a-checkbox>
        </p>
      </a-col>
    </a-row>
  </a-col>
  <bests-modal
    v-model="visible"
    :funds="fundsDefaultSearchesStore.results"
    :searcher="fundsStore.searchFundsByIsinTkr"
  ></bests-modal>
</template>

<script setup>
import { computed, ref, onMounted, defineEmits, defineProps } from "vue";
import { useFundsDefaultSearchesStore } from "@/stores/FundsDefaultSearchesStore";
import { useModelWrapper } from "@/composables/useModelWrapper";
import { useI18n } from "vue-i18n";
import BestsModal from "@/components/wiseral/BestsModal";
import { useFundsStore } from "@/stores/FundsStore";

const fundsDefaultSearchesStore = useFundsDefaultSearchesStore();
const fundsStore = useFundsStore();

const props = defineProps({
  modelValue: { type: Number },
});

const emit = defineEmits(["update:modelValue"]);

const value = useModelWrapper(props, emit);

const { t } = useI18n();
const auto = ref(false);
const visible = ref(false);
const isLoading = ref(false);
const selectRef = ref(null);

const searchFilters = computed(() => {
  return fundsDefaultSearchesStore.searches
    .map((search) => ({
        ...search,
        data: search.data,
      }))
    .filter((search) => search.data.length > 0)
    .map((search) => ({
      label: t(`search_funds.select_stored_options.${search.name}`, search.name),
      options: search.data.map((option) => {
        return {
          value: option.id,
          label: t(`search_funds.select_stored_options.${option.description}`, option.description),
        };
      }),
    }));
});

const search = async (newvalue) => {
  if (newvalue) {
    try {
      isLoading.value = true;
      await fundsDefaultSearchesStore.getSearchResults(newvalue, auto.value);
      visible.value = true;
    } catch (err) {
      console.log(err);
    } finally {
      isLoading.value = false;
    }
  }
};

const handleSelect = (e) => {
  search(e);
};

onMounted(() => {
  fundsDefaultSearchesStore.getDefaultSearchs();
});
</script>

<style scoped lang="scss">
.search-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  button {
    width: 100%;
  }
}

.the-checkbox {
  margin-top: 18px;
}

:deep(.ant-card-body) {
  padding: 0 !important;
}
:deep(.native-select) {
  border: 1px #000 solid;
  padding: 0 !important;
  .ant-select-selector {
    border: none !important;
    height: 100% !important;
  }

  &.ant-select-open {
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
  }
}
</style>
